import BigNumber from 'bignumber.js';

import { useFormatter } from '@/lib/intl';

type Props = {
  amount: bigint | BigNumber.Value;
  currency?: string;
  className?: string;
};

export function CryptoAmount({ amount, currency, className }: Props) {
  const formatter = useFormatter();

  const rounded = formatter.crypto({ value: amount, currency });
  const full = formatter.cryptoFull({ value: amount, currency });
  const needsTooltip = full !== rounded;

  return (
    <span className={className} title={needsTooltip ? full : undefined}>
      {full}
    </span>
  );
}
