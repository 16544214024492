import React from 'react';
import cx from 'classnames';

import { IconName, Icons } from './Icons';
import styles from './IconButton.module.scss';

type CustomProps = {
  size: 'tiny' | 'small' | 'medium' | 'large';
  variant?: 'light' | 'solid-light' | 'dark' | 'transparent';
  disabled?: boolean;
  iconName: IconName;
  label?: string;
  labelHidden?: boolean;
};

type ButtonProps = { as: 'button' } & Omit<React.ComponentPropsWithoutRef<'button'>, 'as'> & CustomProps;
type LinkProps = { as: 'a' } & Omit<React.ComponentPropsWithoutRef<'a'>, 'as'> & CustomProps;

type Props = LinkProps | ButtonProps;

export function IconButton({
  size,
  className,
  iconName,
  variant = 'transparent',
  label,
  labelHidden = false,
  ...props
}: Props) {
  const hasLabel = label && !labelHidden;

  const wrapperClasses = cx(styles.outerButton, {
    [styles.withLabel]: hasLabel,
  });

  const buttonClasses = cx(
    styles.iconButton,
    styles[size],
    variant === 'solid-light' ? styles.solidLight : styles[variant],
    className,
  );

  const Icon = Icons[iconName];
  const Label = () => (hasLabel ? <span className={styles.label}>{label}</span> : null);

  if (props.as === 'a') {
    return (
      <a className={wrapperClasses} {...props}>
        <span className={buttonClasses}>
          <Icon className={styles.icon} />
        </span>

        <Label />
      </a>
    );
  }

  return (
    <button className={wrapperClasses} {...props} type={props.type ?? 'button'}>
      <span className={buttonClasses}>
        <Icon className={styles.icon} />
      </span>

      <Label />
    </button>
  );
}
