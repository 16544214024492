import React, { forwardRef } from 'react';
import cx from 'classnames';

import { PolymorphicComponentProp, PolymorphicRef } from '@/lib/type-utils';

import { Icons } from './Icons';
import styles from './Button.module.scss';

export type BaseButtonProps<C extends React.ElementType> = PolymorphicComponentProp<
  C,
  {
    size?: 'small' | 'medium' | 'large';
    iconBefore?: React.ReactNode;
    iconAfter?: React.ReactNode;
    fullWidth?: boolean;
    disabled?: boolean;
  }
>;

export type ButtonProps<C extends React.ElementType> = BaseButtonProps<C> & {
  variant: 'dark' | 'light' | 'primary' | 'danger' | 'danger-light' | 'link' | 'unstyled';
  shape?: 'normal' | 'pill';
};

const ButtonRenderFn = <T extends React.ElementType = 'button'>(props: ButtonProps<T>, ref: PolymorphicRef<T>) => {
  const {
    as = 'button',
    variant,
    size = 'medium',
    shape = 'normal',
    className,
    fullWidth = false,
    children,
    iconBefore,
    iconAfter,
    ...rest
  } = props;

  const buttonClasses = cx(
    styles.button,
    styles[size],
    styles[variant === 'danger-light' ? 'dangerLight' : variant],
    { [styles.fullWidth]: fullWidth, [styles.pill]: shape === 'pill' },
    className,
  );

  const content = (
    <>
      {iconBefore ? <span className={styles.iconBefore}>{iconBefore}</span> : null}
      <span className={styles.content}>{children}</span>
      {iconAfter ? <span className={styles.iconAfter}>{iconAfter}</span> : null}
    </>
  );

  const Component = as;
  return (
    <Component ref={ref} className={buttonClasses} {...rest} type={rest.type ?? 'button'}>
      {content}
    </Component>
  );
};

export const Button = forwardRef(ButtonRenderFn) as <T extends React.ElementType>(
  p: ButtonProps<T> & { ref?: PolymorphicRef<T> },
) => React.ReactElement;

export type LoadingButtonProps<C extends React.ElementType = 'button'> = ButtonProps<C> & {
  loading?: boolean;
  loadingText?: string;
};

export function LoadingButton<L extends React.ElementType = 'button'>(props: LoadingButtonProps<L>) {
  const { loading = false, loadingText, disabled, iconBefore, children, ...rest } = props;

  const icon = loading ? <Icons.Spinner spin aria-hidden="true" /> : iconBefore;

  const content = loading && loadingText ? loadingText : children;

  return (
    <Button
      // We have to cast the 'rest' prop because otherwise TS will not ble able to calculate the union types
      {...(rest as ButtonProps<L>)}
      iconBefore={icon}
      disabled={disabled || loading}
    >
      {content}
    </Button>
  );
}
