'use client';

import { ReactNode, useMemo, useState } from 'react';
import { CoreTypes } from '@walletconnect/types';

import { OperationModal } from '@/components/OperationModal';
import { useWalletConnectState } from '@/lib/wallet-connect/browser';

import { ThreatPrompt } from '../_components/ThreatPrompt';
import { VerifyInfoBox } from '../_components/VerifyInfoBox';
import { ProjectInfoCard } from '../_components/ProjectInfoCard';

import type { LoaderProps } from './ModalFooter';
import { ModalFooter } from './ModalFooter';
import styles from './ModalWrapper.module.scss';

type Props = {
  children: ReactNode;
  metadata: CoreTypes.Metadata;
  onApprove: () => void;
  onReject: () => void;
  intention?: string;
  infoBoxCondition?: boolean;
  infoBoxText?: string;
  approveLoader?: LoaderProps;
  rejectLoader?: LoaderProps;
  disableApprove?: boolean;
  disableReject?: boolean;
};

export function ModalWrapper({
  children,
  metadata,
  onApprove,
  onReject,
  approveLoader,
  rejectLoader,
  intention,
  infoBoxCondition,
  infoBoxText,
  disableApprove,
  disableReject,
}: Props) {
  const { modal } = useWalletConnectState();
  const currentRequestVerifyContext = modal.data?.currentRequestVerifyContext;

  const [threatAcknowledged, setThreatAcknowledged] = useState(false);

  const isScam = currentRequestVerifyContext?.verified.isScam;
  const isDisplayMainContent = !isScam || threatAcknowledged;

  const threatPromptContent = useMemo(() => {
    return <ThreatPrompt metadata={metadata} onApprove={() => setThreatAcknowledged(true)} onReject={onReject} />;
  }, [metadata, onReject]);

  const footer = (
    <ModalFooter
      onApprove={onApprove}
      onReject={onReject}
      approveLoader={approveLoader}
      rejectLoader={rejectLoader}
      infoBoxCondition={infoBoxCondition}
      infoBoxText={infoBoxText}
      disableApprove={disableApprove}
      disableReject={disableReject}
    />
  );

  const modalContent = useMemo(() => {
    return (
      <>
        <div className={styles.divider} />
        <div className={styles.container}>{children}</div>
        <VerifyInfoBox />
      </>
    );
  }, [children]);

  return (
    <OperationModal
      isOpen={modal.isOpen}
      preventCancel
      hideCloseButton
      // Closing is handled by the reducer
      onClose={() => {}}
      heading={isDisplayMainContent && <ProjectInfoCard metadata={metadata} intention={intention} />}
      description=""
      footer={isDisplayMainContent && footer}
    >
      {isDisplayMainContent ? modalContent : threatPromptContent}
    </OperationModal>
  );
}
