'use client';
import { useEffect, Dispatch, useCallback, useRef, useState } from 'react';
import { IWeb3Wallet } from '@walletconnect/web3wallet';
import { useTranslations } from 'next-intl';

import { showDismissableAlertToast } from '@/components/Toasts';
import { useLocalStorage } from '@/lib/hooks/browser';
import { createWeb3Wallet, defaultChainId, defaultVault, initializeEIP155Wallet } from '@/lib/wallet-connect';
import { Actions, ActiveVault } from '@/lib/wallet-connect/browser';

export function useWeb3Wallet(dispatch: Dispatch<Actions>) {
  const t = useTranslations('Toasts.WalletConnectToasts.InitializationError');

  const [mounted, setMounted] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const web3walletRef = useRef<IWeb3Wallet | null>(null);

  const [eip155Address] = useLocalStorage<string>('WALLET_CONNECT.EIP155_ADDRESS', '');
  const [activeVault] = useLocalStorage<ActiveVault>('WALLET_CONNECT.ACTIVE_VAULT', defaultVault);
  const [activeChainId] = useLocalStorage<string>('WALLET_CONNECT.ACTIVE_CHAIN_ID', defaultChainId);

  const onInitialize = useCallback(async () => {
    let activeEIP155Address = eip155Address;

    try {
      const { eip155Addresses } = await initializeEIP155Wallet();
      activeEIP155Address = eip155Address || (eip155Addresses.length > 0 ? eip155Addresses[0] : '');
    } catch {
      showDismissableAlertToast(t('title'), t('description'), 'error');
    } finally {
      dispatch({
        type: 'set-settings',
        payload: { activeChainId, activeVault, addresses: { eip155Address: activeEIP155Address } },
      });
    }

    try {
      const web3Wallet = await createWeb3Wallet();
      web3walletRef.current = web3Wallet;
    } catch {
      showDismissableAlertToast(t('title'), t('description'), 'error');
    } finally {
      setInitialized(true);
    }
  }, [eip155Address, t, dispatch, activeChainId, activeVault]);

  useEffect(() => {
    // ensure that Wallet Connect is initialized only once
    setMounted(true);
    return () => setMounted(false);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    if (!initialized) {
      onInitialize();
    }
  }, [initialized, mounted, onInitialize]);

  return { initialized, web3Wallet: web3walletRef.current };
}
