'use client';
import { IconButton } from '@/lib/io-kit/IconButton';

import styles from './OpenInExplorerIconButton.module.scss';

type Props = {
  explorerUri: string;
  'data-testid'?: string;
};

export function OpenInExplorerIconButton({ explorerUri, 'data-testid': testId }: Props) {
  return (
    <IconButton
      iconName="ExternalLink"
      size="small"
      as="a"
      href={explorerUri}
      target="_blank"
      rel="noreferrer"
      data-testid={testId}
      className={styles.explorerLink}
      onClick={handleStopPropagatingLinkClick}
    />
  );
}

function handleStopPropagatingLinkClick(e: React.MouseEvent<HTMLAnchorElement>) {
  e.stopPropagation();
}
