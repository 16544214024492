'use client';
import cx from 'classnames';
import Image from 'next/image';
import { useTranslations } from 'next-intl';

import { Heading } from '@/lib/io-kit/Heading';
import { Icons } from '@/lib/io-kit/Icons';
import { LinkExternal } from '@/components/Link';
import { Button, LoadingButton } from '@/lib/io-kit/Button';
import { useWalletConnectState } from '@/lib/wallet-connect/browser';

import styles from './DappItem.module.scss';

type Props = {
  icon: string;
  title: string;
  description: string;
  linkHref: string;
  linkLabel: string;
  variant?: 'normal' | 'light';
};

export default function DappItem({ title, icon, description, linkHref, linkLabel, variant = 'light' }: Props) {
  const t = useTranslations('Components.Dapps.DappItem');

  const { isUserConnected, isLoading, mutations } = useWalletConnectState();

  const isWalletConnect = title === 'WalletConnect';

  return (
    <article className={cx(styles.container, styles[variant])}>
      <div className={styles.iconWrapper}>
        <Image src={icon} alt={`${title} icon`} className={styles.icon} width={60} height={60} />
        <div className={styles.title}>
          <Heading as="h2" variant="heading5">
            {title}
          </Heading>
          <LinkExternal href={linkHref} className={styles.link} variant="on-hover">
            <Icons.ExternalLinkRounded className={styles.linkIcon} />
            <span>{linkLabel}</span>
          </LinkExternal>
        </div>
      </div>
      <div className={styles.description}>{description}</div>
      <div className={styles.spacing} />
      <div className={styles.footer}>
        {isWalletConnect ? (
          <LoadingButton
            variant="unstyled"
            className={cx(styles.button, styles.walletConnectColor)}
            as="a"
            loading={isLoading}
            onClick={() => mutations.toggleSidebar()}
          >
            {isUserConnected ? t('update') : t('connect')}
          </LoadingButton>
        ) : (
          <LinkExternal href={linkHref} variant="unstyled">
            <Button variant="light" className={styles.button} as="a">
              {t('open')}
            </Button>
          </LinkExternal>
        )}
      </div>
    </article>
  );
}
