import { Network } from '@/lib/models';
import { EIP155_SIGNING_METHODS, isEIP712, DefinitionList, EIP712, GenericMessage } from '@/lib/wallet-connect';
import { hexToAscii } from '@/lib/web3-utils';

// @see {@link https://github.com/krakenfx/wallet/blob/main/modules/wallet-connect/web3Wallet/ethereum/utils/adaptToGenericMessage.ts}

/**
 * Converts EIP712 typed data into a list of definitions for display.
 * @param data The EIP712 data object containing domain and message properties.
 * @returns An array of definition objects with title and description properties.
 */
function adaptEIP712ToDefinitionList(data: EIP712): DefinitionList {
  const domainEntries = Object.entries(data.domain);
  const messageEntries = Object.entries(data.message);
  const definitionList: DefinitionList = [];

  for (const [domainOrMessageKey, domainOrMessageValue] of [...domainEntries, ...messageEntries]) {
    if (domainOrMessageValue === null || typeof domainOrMessageValue !== 'object') {
      definitionList.push({ title: domainOrMessageKey, description: `${domainOrMessageValue}` });
      continue;
    }

    const domainOrMessageValueEntries = Object.entries(domainOrMessageValue);
    let description = '';
    for (const [i, [domainOrMessageValueKey, domainOrMessageValueValue]] of domainOrMessageValueEntries.entries()) {
      const isNotLast = i < domainOrMessageValueEntries.length - 1;

      try {
        description += `${domainOrMessageValueKey}: ${
          typeof domainOrMessageValueValue === 'string'
            ? domainOrMessageValueValue
            : JSON.stringify(domainOrMessageValueValue)
        }${isNotLast ? '\n' : ''}`;
      } catch (err) {
        console.error(err, 'ERROR_CONTEXT_PLACEHOLDER');
      }
    }

    definitionList.push({ title: domainOrMessageKey, description });
  }

  return definitionList;
}

/**
 * Converts transaction data into a list of definitions for display.
 * @param transaction The transaction object to be adapted.
 * @returns An array of definition objects with title and description properties.
 */
function adaptTransactionToDefinitionList(transaction: any): DefinitionList {
  const definitionList: DefinitionList = Object.entries(transaction).map(([key, value]) => ({
    title: key,
    description: `${value}`,
  }));

  const methodCode = transaction?.data?.slice(0, 10);
  if (methodCode)
    definitionList.push({
      title: 'method',
      description: methodCode,
    });

  return definitionList;
}

/**
 * Adapts various types of signing methods into a generic message format.
 * @param signMethod The signing method used.
 * @param requestParams Parameters associated with the signing request.
 * @param additionalParams Additional parameters including network identifier and application details.
 * @returns A GenericMessage object containing structured information about the message.
 */
export function adaptToGenericMessage(
  signMethod: string,
  requestParams: any,
  additionalParams: { chainId?: string; dappName?: string; dappSite?: string; vaultName?: string },
): GenericMessage {
  let address = '';
  let heading;
  let _message: string;
  let message:
    | string
    | {
        title: string;
        description: string;
      }[] = '';
  let rawMessage: string | EIP712 = '';

  switch (signMethod) {
    case EIP155_SIGNING_METHODS.PERSONAL_SIGN: {
      [_message, address] = requestParams;
      heading = 'Sign';
      message = _message;
      rawMessage = _message;
      break;
    }
    case EIP155_SIGNING_METHODS.ETH_SIGN: {
      [address, _message] = requestParams;
      heading = 'Sign';
      message = _message;
      rawMessage = _message;
      break;
    }
    case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA:
    case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V4: {
      const [, message_] = requestParams;

      if (typeof message_ === 'string') {
        try {
          const parsedMessage = JSON.parse(message_);

          if (isEIP712(parsedMessage)) {
            heading = parsedMessage.primaryType;
            message = adaptEIP712ToDefinitionList(parsedMessage);
            rawMessage = parsedMessage;
          } else {
            message = message_;
            rawMessage = message_;
          }
        } catch {
          message = message_;
          rawMessage = message_;
        }
      } else if (isEIP712(message_)) {
        heading = message_.primaryType;
        message = adaptEIP712ToDefinitionList(message_);
        rawMessage = message_;
      }

      [address] = requestParams;
      break;
    }
    case EIP155_SIGNING_METHODS.ETH_SEND_TRANSACTION:
    case EIP155_SIGNING_METHODS.ETH_SIGN_TRANSACTION: {
      const [transaction] = requestParams;
      heading = 'Transaction';
      address = transaction.from;
      message = adaptTransactionToDefinitionList(transaction);
      rawMessage = transaction;
      break;
    }
  }

  const allMessages = typeof message === 'string' ? [{ title: 'message', description: hexToAscii(message) }] : message;

  if (additionalParams.dappName) {
    allMessages.push({
      title: 'dapp',
      description: additionalParams.dappName,
    });
  }

  if (additionalParams.chainId) {
    const chainId = additionalParams.chainId.split(':')[1];
    const network = Network.getNetworkByChainId(chainId);
    const networkName = Network.getDisplayName(network);
    allMessages.push({
      title: 'network',
      description: networkName,
    });

    const currency = Network.getNetworkTickerByChainId(chainId);
    if (currency) {
      allMessages.push({
        title: 'currency',
        description: currency,
      });
    }
  }

  if (additionalParams.dappSite) {
    allMessages.push({
      title: 'site',
      description: additionalParams.dappSite,
    });
  }

  if (additionalParams.vaultName) {
    allMessages.push({
      title: 'vaultName',
      description: additionalParams.vaultName,
    });
  }

  return {
    type: 'generic-message',
    address,
    heading,
    message: allMessages,
    rawMessage,
  };
}

/**
 * Extracts and sorts fields from a GenericMessage based on a specified order.
 * This function handles both simple key-value pairs and complex structured data
 * that might be formatted as newline-separated key-value pairs.
 *
 * @param {GenericMessage} message - The message object containing an array of message items.
 * @param {string[]} fields - An array of field names specifying the order and subset of fields to extract.
 * @returns {Array<{key: string, value: string}>} An array of objects with 'key' and 'value' properties, sorted according to the order specified in 'fields'.
 */
export function extractFields(message: GenericMessage, fields: string[]) {
  const fieldSet = new Set(fields);
  let extractedDetails = [];

  for (const item of message.message) {
    if (fieldSet.has(item.title)) {
      // Handle simple key-value pairs directly
      extractedDetails.push({
        key: item.title,
        value: item.description,
      });
    } else if (typeof item.description === 'string' && item.description.includes('\n')) {
      // Check if the description contains structured data formatted as key-value pairs
      const detailItems = parseKeyValuePairs(item.description);
      extractedDetails.push(...detailItems.filter((detail) => fieldSet.has(detail.key)));
    }
  }

  // Sort the extracted details by their order in the fields array to maintain consistency in display
  extractedDetails.sort((a, b) => fields.indexOf(a.key) - fields.indexOf(b.key));
  return extractedDetails;
}

/**
 * Parses a description containing newline-separated key-value pairs into an array of objects.
 * This function is designed to handle descriptions that contain structured data formatted as key-value pairs.
 * @param {string} description - The string containing key-value pairs.
 * @returns {Array<{key: string, value: string}>} Parsed key-value pairs as objects.
 */
function parseKeyValuePairs(description: string): Array<{ key: string; value: string }> {
  return description.split('\n').map((detail) => {
    const [key, value] = detail.split(':').map((part) => part.trim());
    return { key, value };
  });
}
