'use client';

import { useEffect, useMemo, ReactNode } from 'react';

import { useWalletConnectState } from '@/lib/wallet-connect/browser';

import {
  AuthRequestModal,
  SessionSubmittedModal,
  SessionProposalModal,
  SessionSignModal,
  SessionSignTypedDataModal,
  SessionSendTransactionModal,
  SessionUnsupportedMethodModal,
} from '../modals';

import styles from './ModalProvider.module.scss';

type WalletConnectModalProviderProps = {
  children: ReactNode;
};

export function WalletConnectModalProvider({ children }: WalletConnectModalProviderProps) {
  const { modal, sidebar, mutations } = useWalletConnectState();
  const { isOpen, view } = modal;

  const componentView = useMemo(() => {
    if (!view) return null;
    const ModalComponent = {
      AuthRequestModal: AuthRequestModal,
      SessionProposalModal: SessionProposalModal,
      SessionSendTransactionModal: SessionSendTransactionModal,
      SessionSignModal: SessionSignModal,
      SessionSignTypedDataModal: SessionSignTypedDataModal,
      SessionUnsupportedMethodModal: SessionUnsupportedMethodModal,
      SessionSubmittedModal: SessionSubmittedModal,
    }[view];

    return ModalComponent ? <ModalComponent /> : null;
  }, [view]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add(styles.withModalOpen);
      // Auto closing sidebar on open modal to avoid scroll issue on modal content
      if (view !== 'SessionProposalModal' && sidebar.isOpen) {
        mutations.toggleSidebar();
      }
    } else {
      document.body.classList.remove(styles.withModalOpen);
    }
  }, [isOpen, view, sidebar.isOpen, mutations]);

  return (
    <>
      {children}
      {isOpen && componentView}
    </>
  );
}
