'use client';
import { useCallback, useState } from 'react';
import { useTranslations } from 'next-intl';

import { showDismissableAlertToast } from '@/components/Toasts';
import { useWalletConnectState } from '@/lib/wallet-connect/browser';
import {
  approveEIP155Request,
  rejectEIP155Request,
  adaptToGenericMessage,
  getSignTypedDataParamsData,
} from '@/lib/wallet-connect';

import { PermissionDetailsCard } from '../_components/PermissionDetailsCard';
import { RequestDataCard } from '../_components/RequestDataCard';
import { ModalWrapper } from '../modal-wrapper';
import { MemoDropdown } from '../_components/MemoDropdown';

import styles from './SessionSignTypedDataModal.module.scss';

export function SessionSignTypedDataModal() {
  const t = useTranslations('Components.WalletConnect.Modals');

  const { web3Wallet, modal, mutations, settings } = useWalletConnectState();

  const requestEvent = modal.data?.requestEvent;
  const requestSession = modal.data?.requestSession;
  const [memo, setMemo] = useState('');
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [isLoadingReject, setIsLoadingReject] = useState(false);

  // Handle approve action
  const onApprove = useCallback(async () => {
    if (requestEvent) {
      setIsLoadingApprove(true);
      try {
        mutations.openModal('SessionSubmittedModal', modal.data);
        const response = await approveEIP155Request({
          requestEvent,
          requestSession,
          data: {
            vaultId: settings.activeVault.id,
            memo,
          },
        });
        await web3Wallet.respondSessionRequest({
          topic: requestEvent.topic,
          response,
        });
      } catch (err) {
        showDismissableAlertToast('Error', (err as Error).message, 'error');
      } finally {
        setIsLoadingApprove(false);
        mutations.closeModal();
      }
    }
  }, [requestEvent, mutations, modal.data, requestSession, settings.activeVault.id, memo, web3Wallet]);

  // Handle reject action
  const onReject = useCallback(async () => {
    if (requestEvent) {
      setIsLoadingReject(true);
      const response = rejectEIP155Request(requestEvent);
      try {
        await web3Wallet.respondSessionRequest({
          topic: requestEvent.topic,
          response,
        });
      } catch (err) {
        showDismissableAlertToast('Error', (err as Error).message, 'error');
      } finally {
        setIsLoadingReject(false);
        mutations.closeModal();
      }
    }
  }, [requestEvent, web3Wallet, mutations]);

  // Ensure request and wallet are defined
  if (!requestEvent || !requestSession) {
    return <div className={styles.text}>{t('Common.missingRequestData')}</div>;
  }

  // Get required request data
  const { params } = requestEvent;
  const { request, chainId } = params;
  const {
    peer: { metadata },
  } = requestSession;
  const { name, url } = metadata;

  // Get data
  const data = getSignTypedDataParamsData(request.params);
  const genericMessage = adaptToGenericMessage(request.method, request.params, {
    chainId,
    dappName: name,
    dappSite: new URL(url).hostname,
    vaultName: settings.activeVault.name,
  });

  const isPermissionRequest = data?.domain?.name === 'eth_getPermissions_v1';
  let permissionScope = [];
  if (isPermissionRequest) {
    permissionScope = data?.message?.scope || [];
  }

  return (
    <ModalWrapper
      intention={t('SessionTypes.SignTypedData.intention')}
      metadata={metadata}
      onApprove={onApprove}
      onReject={onReject}
      approveLoader={{ active: isLoadingApprove }}
      rejectLoader={{ active: isLoadingReject }}
    >
      {isPermissionRequest && permissionScope.length > 0 ? (
        <PermissionDetailsCard scope={permissionScope} />
      ) : (
        <>
          <RequestDataCard message={genericMessage} />
          <MemoDropdown memo={memo} onMemoChange={setMemo} />
        </>
      )}
    </ModalWrapper>
  );
}
