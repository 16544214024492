'use client';
// eslint-disable-next-line no-restricted-imports
import { useQuery, gql } from '@apollo/client';
import { useTranslations } from 'next-intl';
import Image from 'next/image';

import { VaultOption } from '@/components/VaultSelect';
import { Form } from '@/lib/io-kit/Form';
import { Alert } from '@/lib/io-kit/Alert';
import { Jazzicon } from '@/lib/io-kit/Jazzicon';
import { truncateValue } from '@/lib/truncate-value';
import { Asset, AssetModelFragment } from '@/lib/models';
import { FragmentType } from '@/lib/gql';
import { disallowedNetworkTypes } from '@/lib/wallet-connect';

import styles from './VaultInfo.module.scss';

type Props = {
  vaultId: string;
};

const GET_VAULT_DETAILS = gql`
  query GetVaultDetails($vaultId: ID!) {
    vault(vaultId: $vaultId) {
      id
      name
      balanceUsd: balance(asFiat: USD)
      threshold
      addresses: visibleAssets {
        id
        balanceAsCoin: balance
        balanceUsd: balance(asFiat: USD)
        addressHash: publicKey
        asset {
          id
          createdAt
          symbol
          name
          rateUsd: rate(withFiat: USD)
          decimals
        }
      }
    }
  }
`;

type Address = {
  id: string;
  balanceAsCoin: number;
  balanceUsd: number;
  addressHash: string;
  asset: FragmentType<typeof AssetModelFragment>;
};

export function VaultInfo({ vaultId }: Props) {
  const t = useTranslations('Components.WalletConnect.VaultInfo');

  const { data, loading, error } = useQuery(GET_VAULT_DETAILS, {
    variables: { vaultId },
    skip: !vaultId,
  });

  if (loading)
    return (
      <Form.Group>
        <Form.Label>{t('vault')}</Form.Label>
        <Image src="/loading.svg" width={25} height={25} alt="Loading…" />
      </Form.Group>
    );

  if (error)
    return (
      <Form.Group>
        <Form.Label>{t('vault')}</Form.Label>
        <Alert variant="error" inline title={t('errorLoadingVaultInfo')} />
      </Form.Group>
    );

  const vault = data?.vault;

  if (!vault)
    return (
      <Form.Group>
        <Form.Label>{t('vault')}</Form.Label>
        <Alert variant="warning" inline title={t('noVaultInfoReturned')} />
      </Form.Group>
    );

  // Filter out vault addresses linked with disallowed networks
  const filteredAddresses = vault.addresses.find((address: Address) => {
    const network = Asset.getNetwork(address.asset);
    return network && !disallowedNetworkTypes.includes(network);
  });

  const vaultData: VaultOption = {
    value: vault.id,
    label: vault.name,
    threshold: vault.threshold,
    isSelected: true,
    address: filteredAddresses,
  };

  return (
    <Form.Group>
      <Form.Label>From</Form.Label>
      <div className={styles.wrapper}>
        <Jazzicon.Vault size="huge" vaultId={vaultData.value} threshold={vaultData.threshold} />
        <div className={styles.info}>
          <div className={styles.vaultName}>{vaultData.label}</div>
          {vaultData?.address?.balanceUsd && vaultData.address.addressHash && (
            <div className={styles.walletAndBalance}>{truncateValue(vaultData.address.addressHash)}</div>
          )}
        </div>
      </div>
    </Form.Group>
  );
}
