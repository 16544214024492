import { Network } from '@/lib/models';
import { EIP155Chain } from '@/lib/wallet-connect';

/**
 * Enum for different environments where the application can run.
 */
enum Environment {
  TEST = 'test',
  DEV = 'development',
  STAGING = 'staging',
  PROD = 'production',
}

/**
 * Interface for configuring RPC URLs for different environments.
 */
type RPCConfig = {
  [key in Network.Type]?: { default: string } & Partial<Record<Environment, string>>;
};

/**
 * Configuration object mapping chain IDs to their respective RPC URLs for each environment.
 */
const RPC_URLS: RPCConfig = {
  // Main chains
  [Network.Type.Ethereum]: {
    default: 'https://convincing-attentive-snowflake.quiknode.pro/a244f8ae1805d3f0c6a699415c594f4144b9e4a2/',
  },
  [Network.Type.Polygon]: {
    default: 'https://autumn-purple-pond.matic.quiknode.pro/cdff4496df7f82e232ca5c863a8e7b44cb450221/',
    [Environment.DEV]: 'https://dawn-wider-thunder.matic.quiknode.pro/40224382c7a8682ab6ce9f217142495e9339b2e1/',
  },
  [Network.Type.BinanceSmartChain]: {
    default: 'https://spring-wispy-star.bsc.quiknode.pro/0583e75f86b3f9be587fe7fb01678f9600f0eda1/',
  },
  [Network.Type.Avalanche]: {
    default:
      'https://alpha-solitary-ensemble.avalanche-mainnet.quiknode.pro/3892ff231edf369e4d72d89c1e8bf964898746ec/ext/bc/C/rpc/',
  },
  [Network.Type.Fantom]: {
    default: 'https://greatest-winter-dust.fantom.quiknode.pro/0f54de2a4d1a9db5b22982482da39a0bdbf87278/',
  },
  [Network.Type.Arbitrum]: {
    default:
      'https://sparkling-omniscient-snow.arbitrum-mainnet.quiknode.pro/40ab0c27e931dbfe97c3bf94dc64c3bb71e541d7/',
  },
  [Network.Type.Optimism]: {
    default: 'https://intensive-smart-tab.optimism.quiknode.pro/b2077bc351fc63957e5d8420236b1fdd1bfc5945/',
  },
  [Network.Type.Base]: {
    default: 'https://snowy-still-violet.base-mainnet.quiknode.pro/dfbaca9edc0b52eec5eb3f7840ac2ed030aade64/',
  },
  // Test chains
  [Network.Type.TestnetSepoliaEthereum]: {
    default: 'https://withered-rough-wildflower.ethereum-sepolia.quiknode.pro/9843b2c5e9160858c7257cd887be89461c00927/',
  },
};

/**
 * Retrieves the RPC URL for a specified chain ID and environment.
 * Returns the default RPC URL if a specific environment URL is not configured.
 * @param chainId - The ID of the chain for which to retrieve the RPC URL.
 * @returns The RPC URL or an empty string if not available.
 */
export const getRPCUrl = (networkType: Network.Type): string => {
  const envRPCs = RPC_URLS[networkType];
  return envRPCs?.[process.env.NODE_ENV] || envRPCs?.default || '';
};

/**
 * Filters out chains that do not have a valid RPC URL.
 * @param chains - The chains to filter.
 * @returns The filtered chains.
 */
export const filterChainsWithValidRPC = (chains: Record<string, EIP155Chain>) => {
  let filteredChains: Record<string, EIP155Chain> = {};
  for (const key of Object.keys(chains)) {
    const chain = chains[key];
    const rpcUrl = getRPCUrl(chain.type);
    if (rpcUrl) {
      filteredChains[key] = { ...chain, rpc: rpcUrl };
    }
  }

  return filteredChains;
};
