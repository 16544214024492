import { EIP155Chain, EIP155_CHAINS, TEIP155Chain } from '@/lib/wallet-connect';

export const ALL_CHAINS: Record<string, EIP155Chain> = {
  ...EIP155_CHAINS,
};

/**
 * Get the chain data from the chainId
 * @param chainId The chainId to get the data for
 * @returns The chain data
 */
export function getChainData(chainId?: string) {
  if (!chainId) return;
  const [namespace, reference] = chainId.toString().split(':');
  const chainData = Object.values(ALL_CHAINS).find(
    (chain) => chain.chainId == +reference && chain.namespace === namespace,
  );
  return chainData;
}

/**
 * Check if chain is part of EIP155 standard
 */
export function isEIP155Chain(chain: string) {
  return chain.includes('eip155');
}

/**
 * Formats chainId to its name
 */
export function formatChainName(chainId: string) {
  return EIP155_CHAINS[chainId as TEIP155Chain]?.name ?? chainId;
}
