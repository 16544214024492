'use client';

import { useTranslations } from 'next-intl';
import cx from 'classnames';

import styles from './PermissionDetailsCard.module.scss';

type PermissionAction = {
  description: string;
};

type Props = {
  scope: PermissionAction[];
};

export function PermissionDetailsCard({ scope }: Props) {
  const t = useTranslations('Components.WalletConnect.PermissionDetailsCard');

  return (
    <div className={styles.row}>
      <div>
        <h5 className={styles.heading}>{t('requestingPermissions')}</h5>
        {scope.map((action, index) => (
          <p className={cx(styles.text, styles.textLight)} key={index}>
            {action.description}
          </p>
        ))}
      </div>
    </div>
  );
}
