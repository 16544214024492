import React from 'react';
import classNames from 'classnames';

import { buildTestId } from '@/lib/test-ids';

import styles from './Alert.module.scss';
import { Icons } from './Icons';
import { IconButton } from './IconButton';

export type AlertProps = {
  title: React.ReactNode;
  variant: 'warning' | 'success' | 'error' | 'info';
  LeftIcon?: React.ElementType;
  'data-testid'?: string;
  inline?: boolean;
  hideIcon?: boolean;
  onDismiss?: () => void;
};

export function Alert({
  title,
  variant,
  LeftIcon,
  'data-testid': testId,
  inline = false,
  hideIcon = false,
  onDismiss,
}: AlertProps) {
  const dismissible = Boolean(onDismiss);
  const alertClasses = classNames(styles.alert, styles[variant], {
    [styles.dismissible]: dismissible,
    [styles.inline]: inline,
  });

  let IconComponent = LeftIcon;
  if (!IconComponent) {
    IconComponent = variant === 'success' ? Icons.SuccessCircleFaded : Icons.AlertCircleFaded;
  }

  return (
    <div className={alertClasses} data-testid={testId}>
      <div className={styles.main}>
        {hideIcon ? null : (
          <div className={styles.iconWrapper}>
            <IconComponent className={styles.icon} />
          </div>
        )}

        <div className={styles.content}>
          <p data-testid={buildTestId(testId, 'message')}>{title}</p>
        </div>
      </div>

      {dismissible ? (
        <div className={styles.dismissWrapper}>
          <IconButton as="button" iconName="Close" size="small" onClick={onDismiss} aria-label="Close" />
        </div>
      ) : null}
    </div>
  );
}
