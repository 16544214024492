'use client';

import { useTranslations } from 'next-intl';

import { LoadingButton } from '@/lib/io-kit/Button';
import { useWalletConnectState } from '@/lib/wallet-connect/browser';

import styles from './ModalFooter.module.scss';

export interface LoaderProps {
  active?: boolean;
}
type Props = {
  onApprove: () => void;
  onReject: () => void;
  infoBoxCondition?: boolean;
  infoBoxText?: string;
  approveLoader?: LoaderProps;
  rejectLoader?: LoaderProps;
  disableApprove?: boolean;
  disableReject?: boolean;
};

export function ModalFooter({
  onApprove,
  approveLoader,
  onReject,
  rejectLoader,
  infoBoxCondition,
  infoBoxText,
  disableApprove,
  disableReject,
}: Props) {
  const t = useTranslations('Components.WalletConnect.Modals.Common');

  const { modal } = useWalletConnectState();
  const validation = modal.data?.currentRequestVerifyContext?.verified.validation;
  const isScam = modal.data?.currentRequestVerifyContext?.verified.isScam;

  const approveButtonVariant = () => {
    if (isScam) return 'danger';

    switch (validation) {
      case 'INVALID': {
        return 'danger-light';
      }
      default: {
        return 'primary';
      }
    }
  };

  return (
    <>
      {infoBoxCondition && infoBoxText && (
        <div className={styles.infoBox}>
          <span>{infoBoxText}</span>
        </div>
      )}
      <div className={styles.modalFooter}>
        <LoadingButton
          onClick={onReject}
          data-testid="session-reject-button"
          disabled={disableReject || rejectLoader?.active}
          variant="light"
          loading={rejectLoader?.active}
          className={styles.button}
        >
          {t('reject')}
        </LoadingButton>
        <LoadingButton
          onClick={onApprove}
          data-testid="session-approve-button"
          disabled={disableApprove || approveLoader?.active}
          variant={approveButtonVariant()}
          loading={approveLoader?.active}
          className={styles.button}
        >
          {modal.view === 'SessionProposalModal' ? t('accept') : t('submit')}
        </LoadingButton>
      </div>
    </>
  );
}
