'use client';
// eslint-disable-next-line no-restricted-imports
import { useQuery, gql } from '@apollo/client';
import { useTranslations } from 'next-intl';

import { OperationModal } from '@/components/OperationModal';
import { Heading } from '@/lib/io-kit/Heading';
import { Icons } from '@/lib/io-kit/Icons';
import { LinkInternal } from '@/components/Link';
import { Avatar } from '@/lib/io-kit/Avatar';
import { LinkTo } from '@/lib/links';
import { buildTestId } from '@/lib/test-ids';
import { Button } from '@/lib/io-kit/Button';
import { FragmentType, getFragmentData, graphql } from '@/lib/gql';
import { UserAvatarFragment, getUserRole, getUserType } from '@/components/UserAvatar';
import { Alert } from '@/lib/io-kit/Alert';
import { LoadingSection } from '@/components/Loading';
import { useWalletConnectState } from '@/lib/wallet-connect/browser';

import styles from './SessionSubmittedModal.module.scss';

export const WalletConnectRequestSubmittedSigner = graphql(`
  fragment WalletConnectRequestSubmittedSigner on Signer {
    id
    weight
    device {
      id
      name
      user {
        ...UserAvatar
      }
    }
  }
`);

const GET_VAULT_SIGNERS = gql`
  query GetVaultSigners($vaultId: ID!) {
    vault(vaultId: $vaultId) {
      signers {
        id
        weight
        device {
          id
          name
          user {
            id
            details {
              name
              initials
              profilePictureUri
              role
              type
            }
          }
        }
      }
    }
  }
`;

type Props = {
  'data-testid'?: string;
  signers: FragmentType<typeof WalletConnectRequestSubmittedSigner>[];
  trackProgressHref?: string;
};

export function SessionSubmittedModal() {
  const t = useTranslations('Components.WalletConnect.Modals.SessionSubmittedModal');
  const { modal, settings } = useWalletConnectState();
  const { isOpen } = modal;

  const vaultId = settings.activeVault.id;

  const { data, loading, error } = useQuery(GET_VAULT_SIGNERS, {
    variables: { vaultId },
    skip: !vaultId,
  });

  return (
    <OperationModal
      isOpen={isOpen}
      preventCancel
      hideCloseButton
      // Closing is handled by the reducer
      onClose={() => {}}
      largeIcon="SuccessWithRippleEffect"
      heading={
        <Heading as="div" variant="heading5" className={styles.heading}>
          <Icons.Phone className={styles.headingIcon} />
          {t('title')}
        </Heading>
      }
      description={t('description')}
      footer={
        <Footer
          data-testid="sign-request-conformation-modal"
          signers={data?.vault?.signers}
          trackProgressHref={vaultId ? LinkTo.vaultActivity({ vaultId }) : undefined}
        />
      }
    >
      {loading && <LoadingSection />}
      {error && <Alert variant="error" title={t('errorLoadingVaultSigners')} />}
      {data && data.vault && <Approvals signers={data?.vault?.signers} />}

      <div className={styles.alertWrapper}>
        <Alert title={t('doNotClose')} variant="warning" />
      </div>
    </OperationModal>
  );
}

function Approvals({ signers }: Props) {
  const t = useTranslations('Components.WalletConnect.Modals.SessionSubmittedModal');

  if (!signers) return null;

  const signersData = signers.map((signer) => getFragmentData(WalletConnectRequestSubmittedSigner, signer));

  return (
    <div>
      <div className={styles.approvalsTitle}>{t('approvalsSent')}</div>
      <ul className={styles.approvalsList}>
        {signersData.map(({ id, device }) => {
          const userData = getFragmentData(UserAvatarFragment, device.user);
          const {
            id: userId,
            details: { profilePictureUri, initials, name },
          } = userData;

          return (
            <li key={id} className={styles.approvalsListItem}>
              <Avatar
                src={profilePictureUri}
                userId={userId}
                userInitials={initials}
                userName={name}
                userRole={getUserRole(userData)}
                userType={getUserType(userData)}
                size="large"
                highlight="dimmed"
              />
              <span className={styles.approvalsDevice}>{device.name}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function Footer({ trackProgressHref, 'data-testid': testId }: Props) {
  const t = useTranslations('Components.WalletConnect.Modals.SessionSubmittedModal');
  const { mutations } = useWalletConnectState();

  if (!trackProgressHref) return null;

  return (
    <>
      <Button variant="light" onClick={mutations.closeModal}>
        {t('linkToDapps')}
      </Button>

      <LinkInternal variant="unstyled" href={trackProgressHref} passHref>
        <Button
          as="a"
          variant="primary"
          data-testid={buildTestId(testId, 'submitted.track.button')}
          onClick={mutations.closeModal}
        >
          {t('linkToActivityPage')}
        </Button>
      </LinkInternal>
    </>
  );
}
