'use client';

import { produce } from 'immer';
import { IWeb3Wallet, Web3WalletTypes } from '@walletconnect/web3wallet';
import { SessionTypes, SignClientTypes, Verify } from '@walletconnect/types';

interface ModalData {
  proposal?: SignClientTypes.EventArguments['session_proposal'];
  requestEvent?: SignClientTypes.EventArguments['session_request'];
  requestSession?: SessionTypes.Struct;
  request?: Web3WalletTypes.AuthRequest;
  currentRequestVerifyContext?: Verify.Context;
}

export type ViewType =
  | 'AuthRequestModal'
  | 'SessionProposalModal'
  | 'SessionSignModal'
  | 'SessionSignTypedDataModal'
  | 'SessionSendTransactionModal'
  | 'SessionUnsupportedMethodModal'
  | 'SessionSubmittedModal';

export type ActiveVault = {
  id: string;
  name?: string;
};

export type State = {
  sidebar: {
    isOpen: boolean;
  };
  modal: {
    isOpen: boolean;
    view?: ViewType;
    data?: ModalData;
  };
  settings: {
    activeChainId: string;
    activeVault: ActiveVault;
    addresses: {
      eip155Address: string;
      // ... other non eip155 addresses to be added here
    };
    sessions: SessionTypes.Struct[];
  };
  web3Wallet: IWeb3Wallet;
  isLoading: boolean;
  isUserConnected: boolean;
  isUserDisconnect: boolean;
};

export type Actions =
  | { type: 'toggle-sidebar' }
  | { type: 'open-modal'; payload: { view: State['modal']['view']; data: State['modal']['data'] } }
  | { type: 'close-modal' }
  | { type: 'set-settings'; payload: Partial<State['settings']> }
  | { type: 'set-web3-wallet'; payload: { web3Wallet: IWeb3Wallet } }
  | { type: 'set-is-loading'; payload: { isLoading: boolean } }
  | { type: 'set-is-user-connected'; payload: { isUserConnected: boolean } }
  | { type: 'set-is-user-disconnect'; payload: { isUserDisconnect: boolean } };

export const reducer = produce((draft: State, action: Actions) => {
  switch (action.type) {
    case 'toggle-sidebar': {
      draft.sidebar.isOpen = !draft.sidebar.isOpen;
      break;
    }
    case 'open-modal': {
      draft.modal.isOpen = true;
      draft.modal.view = action.payload.view;
      draft.modal.data = action.payload.data;
      break;
    }
    case 'close-modal': {
      draft.modal.isOpen = false;
      draft.modal.view = undefined;
      draft.modal.data = undefined;
      break;
    }
    case 'set-settings': {
      Object.assign(draft.settings, action.payload);
      break;
    }
    case 'set-web3-wallet': {
      draft.web3Wallet = action.payload.web3Wallet;
      break;
    }
    case 'set-is-loading': {
      draft.isLoading = action.payload.isLoading;
      break;
    }
    case 'set-is-user-connected': {
      draft.isUserConnected = action.payload.isUserConnected;
      break;
    }
    case 'set-is-user-disconnect': {
      draft.isUserDisconnect = action.payload.isUserDisconnect;
      break;
    }
  }
});
