import { EIP155Lib } from '..';

export let eip155Wallets: EIP155Lib;

/**
 * Utility function to initialize and connect EIP155 wallet.
 */
export async function initializeEIP155Wallet(): Promise<{ eip155Addresses: string[] }> {
  eip155Wallets = EIP155Lib.init();

  const eip155Addresses = await eip155Wallets.connect();

  return { eip155Addresses };
}
