import { Network } from '@/lib/models';
import { ViewType } from '@/lib/wallet-connect/browser';
import { getRPCUrl, filterChainsWithValidRPC } from '@/lib/wallet-connect';

/**
 * Types
 */
export type TEIP155Chain = keyof typeof EIP155_CHAINS;

export type EIP155Chain = {
  chainId: number;
  name: string;
  type: Network.Type;
  logo: string | null;
  rpc: string;
  namespace: string;
};

const NAMESPACE = 'eip155';

/**
 * Chains
 */
export const EIP155_MAINNET_CHAINS: Record<string, EIP155Chain> = {
  [`${NAMESPACE}:${Network.getChainId(Network.Type.Ethereum)}`]: {
    chainId: Network.getChainId(Network.Type.Ethereum),
    name: Network.getDisplayName(Network.Type.Ethereum),
    type: Network.Type.Ethereum,
    logo: Network.getNetworkIcon(Network.Type.Ethereum),
    rpc: getRPCUrl(Network.Type.Ethereum),
    namespace: NAMESPACE,
  },
  [`${NAMESPACE}:${Network.getChainId(Network.Type.Polygon)}`]: {
    chainId: Network.getChainId(Network.Type.Polygon),
    name: Network.getDisplayName(Network.Type.Polygon),
    type: Network.Type.Polygon,
    logo: Network.getNetworkIcon(Network.Type.Polygon),
    rpc: getRPCUrl(Network.Type.Polygon),
    namespace: NAMESPACE,
  },
  [`${NAMESPACE}:${Network.getChainId(Network.Type.BinanceSmartChain)}`]: {
    chainId: Network.getChainId(Network.Type.BinanceSmartChain),
    name: Network.getDisplayName(Network.Type.BinanceSmartChain),
    type: Network.Type.BinanceSmartChain,
    logo: Network.getNetworkIcon(Network.Type.BinanceSmartChain),
    rpc: getRPCUrl(Network.Type.BinanceSmartChain),
    namespace: NAMESPACE,
  },
  [`${NAMESPACE}:${Network.getChainId(Network.Type.Avalanche)}`]: {
    chainId: Network.getChainId(Network.Type.Avalanche),
    name: Network.getDisplayName(Network.Type.Avalanche),
    type: Network.Type.Avalanche,
    logo: Network.getNetworkIcon(Network.Type.Avalanche),
    rpc: getRPCUrl(Network.Type.Avalanche),
    namespace: NAMESPACE,
  },
  [`${NAMESPACE}:${Network.getChainId(Network.Type.Optimism)}`]: {
    chainId: Network.getChainId(Network.Type.Optimism),
    name: Network.getDisplayName(Network.Type.Optimism),
    type: Network.Type.Optimism,
    logo: Network.getNetworkIcon(Network.Type.Optimism),
    rpc: getRPCUrl(Network.Type.Optimism),
    namespace: NAMESPACE,
  },
  [`${NAMESPACE}:${Network.getChainId(Network.Type.Fantom)}`]: {
    chainId: Network.getChainId(Network.Type.Fantom),
    name: Network.getDisplayName(Network.Type.Fantom),
    type: Network.Type.Fantom,
    logo: Network.getNetworkIcon(Network.Type.Fantom),
    rpc: getRPCUrl(Network.Type.Fantom),
    namespace: NAMESPACE,
  },
  [`${NAMESPACE}:${Network.getChainId(Network.Type.Arbitrum)}`]: {
    chainId: Network.getChainId(Network.Type.Arbitrum),
    name: Network.getDisplayName(Network.Type.Arbitrum),
    type: Network.Type.Arbitrum,
    logo: Network.getNetworkIcon(Network.Type.Arbitrum),
    rpc: getRPCUrl(Network.Type.Arbitrum),
    namespace: NAMESPACE,
  },
  [`${NAMESPACE}:${Network.getChainId(Network.Type.Base)}`]: {
    chainId: Network.getChainId(Network.Type.Base),
    name: Network.getDisplayName(Network.Type.Base),
    type: Network.Type.Base,
    logo: Network.getNetworkIcon(Network.Type.Base),
    rpc: getRPCUrl(Network.Type.Base),
    namespace: NAMESPACE,
  },
};

/**
 * Test chains
 */
export const EIP155_TEST_CHAINS: Record<string, EIP155Chain> = {
  [`${NAMESPACE}:${Network.getChainId(Network.Type.TestnetSepoliaEthereum)}`]: {
    chainId: Network.getChainId(Network.Type.TestnetSepoliaEthereum),
    name: Network.getDisplayName(Network.Type.TestnetSepoliaEthereum),
    type: Network.Type.TestnetSepoliaEthereum,
    logo: Network.getNetworkIcon(Network.Type.TestnetSepoliaEthereum),
    rpc: getRPCUrl(Network.Type.TestnetSepoliaEthereum),
    namespace: NAMESPACE,
  },
};

export const EIP155_CHAINS: Record<string, EIP155Chain> = {
  ...filterChainsWithValidRPC(EIP155_MAINNET_CHAINS),
  // Avoid adding test chains in production
  ...(process.env.NODE_ENV !== 'production' && filterChainsWithValidRPC(EIP155_TEST_CHAINS)),
};

/**
 * Methods
 */
export const EIP155_SIGNING_METHODS = {
  PERSONAL_SIGN: 'personal_sign',
  ETH_SIGN: 'eth_sign',
  ETH_SIGN_TRANSACTION: 'eth_signTransaction',
  ETH_SIGN_TYPED_DATA: 'eth_signTypedData',
  ETH_SIGN_TYPED_DATA_V3: 'eth_signTypedData_v3',
  ETH_SIGN_TYPED_DATA_V4: 'eth_signTypedData_v4',
  ETH_SEND_RAW_TRANSACTION: 'eth_sendRawTransaction',
  ETH_SEND_TRANSACTION: 'eth_sendTransaction',
};

/**
 * Modal view map
 */
const METHOD_TO_MODAL_VIEW_MAP: Record<string, ViewType> = {
  [EIP155_SIGNING_METHODS.ETH_SIGN]: 'SessionSignModal',
  [EIP155_SIGNING_METHODS.PERSONAL_SIGN]: 'SessionSignModal',
  [EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA]: 'SessionSignTypedDataModal',
  [EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V3]: 'SessionSignTypedDataModal',
  [EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V4]: 'SessionSignTypedDataModal',
  [EIP155_SIGNING_METHODS.ETH_SEND_TRANSACTION]: 'SessionSendTransactionModal',
  [EIP155_SIGNING_METHODS.ETH_SIGN_TRANSACTION]: 'SessionSendTransactionModal',
};

/**
 * Determines the appropriate modal view to display based on the request method.
 * This function maps Ethereum Improvement Proposal (EIP) 155 signing methods to their corresponding modal views.
 * If a method does not have a specific modal view associated with it, a default modal view for unsupported methods is returned.
 *
 * @param method The method name from the EIP155 signing request.
 * @returns The name of the modal view that should be displayed for the given method.
 */
export function getModalViewBasedOnRequestMethod(method: string): ViewType {
  return METHOD_TO_MODAL_VIEW_MAP[method] || 'SessionUnsupportedMethodModal';
}
