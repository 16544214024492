import { formatJsonRpcError, formatJsonRpcResult } from '@walletconnect/jsonrpc-utils';
import { SessionTypes, SignClientTypes } from '@walletconnect/types';
import { getSdkError } from '@walletconnect/utils';
import { RequestData, RequestMetadata } from '@iofinnet/io-web3-provider';

import { globalLogger } from '@/lib/logger';
import { Operation } from '@/lib/models';
import {
  encodeEIP191Message,
  getSignParamsMessage,
  getSignTypedDataParamsData,
  eip155Wallets,
  EIP155_SIGNING_METHODS,
} from '@/lib/wallet-connect';

const logger = globalLogger.child({ scope: 'WalletConnect' });

type RequestEventArgs = Omit<SignClientTypes.EventArguments['session_request'], 'verifyContext'>;

export async function approveEIP155Request({
  requestEvent,
  requestSession,
  data,
}: {
  requestEvent: RequestEventArgs;
  requestSession?: SessionTypes.Struct;
  data: RequestData;
}) {
  const { params, id } = requestEvent;
  const { chainId, request } = params;

  logger.debug(
    '[approveEIP155Request] Received request for vaultId',
    data.vaultId,
    'chainId',
    chainId,
    'with request',
    request,
  );

  const metadata: RequestMetadata = {};

  if (requestSession) {
    const peerMetadata = requestSession?.peer?.metadata;
    if (peerMetadata) metadata.sourceUrl = peerMetadata?.url;
  }

  try {
    await eip155Wallets?.switchEthereumChain(chainId);
  } catch {
    return formatJsonRpcError(id, getSdkError('UNSUPPORTED_CHAINS').message);
  }

  switch (request.method) {
    case EIP155_SIGNING_METHODS.ETH_SEND_TRANSACTION: {
      try {
        const transaction = request.params[0];
        metadata.source = Operation.getSourceFromSourceUrl(metadata.sourceUrl, 'transaction');

        const transactionHash = await eip155Wallets.sendTransaction({
          transaction,
          data,
          metadata,
        });
        return formatJsonRpcResult(id, transactionHash);
      } catch (err: any) {
        console.error(err);
        return formatJsonRpcError(id, err.message);
      }
    }

    case EIP155_SIGNING_METHODS.PERSONAL_SIGN:
    case EIP155_SIGNING_METHODS.ETH_SIGN: {
      try {
        const message = getSignParamsMessage(request.params);
        const encodedMessage = encodeEIP191Message(message);
        metadata.source = Operation.getSourceFromSourceUrl(metadata.sourceUrl, 'sign');

        const signedMessage = await eip155Wallets.signMessage({
          message: encodedMessage,
          data,
          metadata,
        });
        return formatJsonRpcResult(id, signedMessage);
      } catch (err: any) {
        console.error(err);
        return formatJsonRpcError(id, err.message);
      }
    }

    // FIXME: error on Hex message (work with text plain)
    // case EIP155_SIGNING_METHODS.ETH_SIGN: {
    //   {
    //     try {
    //       const message = getSignParamsMessage(request.params);
    //       const encodedHexMessage = encodeEIP191MessageAsHex(message);
    //       metadata.source = Operation.getSourceFromSourceUrl(metadata.sourceUrl, 'sign');

    //       const signedMessage = await eip155Wallets.signMessage(vaultId, encodedHexMessage, metadata,);
    //       return formatJsonRpcResult(id, signedMessage);
    //     } catch (err: any) {
    //       console.error(err);
    //       return formatJsonRpcError(id, err.message);
    //     }
    //   }
    // }

    case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA:
    case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V3:
    case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V4: {
      try {
        const { domain, types, message: typeData, primaryType } = getSignTypedDataParamsData(request.params);
        metadata.source = Operation.getSourceFromSourceUrl(metadata.sourceUrl, 'sign');

        const signedData = await eip155Wallets.signTypedData({
          domain,
          types,
          typeData,
          _primaryType: primaryType,
          data,
          metadata,
        });

        let signature = signedData;

        // Used in dapps like Uniswap (for "Swap")
        const isPermitSingle = primaryType === 'PermitSingle';
        // Used in dapps like AAVE (for "Repay with Collateral")
        const isPermit = primaryType === 'Permit';
        // Used in dapps like 1Inch (for "Fusion Swap")
        const isFusion = domain?.name?.includes('1inch') && primaryType === 'Order';

        if (isPermitSingle || isFusion) {
          // Remove the two last characters from the signature to avoid error 'invalid signature'
          signature = signedData.slice(0, -2);
        } else if (isPermit) {
          // Add 1C (hex of 28) to the end of the signature to avoid error 'invalid signature'
          signature = signedData.slice(0, 130) + '1C';
        }

        return formatJsonRpcResult(id, signature);
      } catch (err: any) {
        console.error(err);
        return formatJsonRpcError(id, err.message);
      }
    }

    case EIP155_SIGNING_METHODS.ETH_SIGN_TRANSACTION: {
      try {
        const transaction = request.params[0];
        metadata.source = Operation.getSourceFromSourceUrl(metadata.sourceUrl, 'sign');

        const signature = await eip155Wallets.signTransaction({
          transaction,
          data,
          metadata,
        });
        return formatJsonRpcResult(id, signature);
      } catch (err: any) {
        console.error(err);
        return formatJsonRpcError(id, err.message);
      }
    }

    default: {
      return formatJsonRpcError(id, getSdkError('INVALID_METHOD').message);
    }
  }
}

export function rejectEIP155Request(request: RequestEventArgs) {
  const { id } = request;
  return formatJsonRpcError(id, getSdkError('USER_REJECTED').message);
}
