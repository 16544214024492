import { useTranslations } from 'next-intl';

import { Form } from '@/lib/io-kit/Form';
import { Collapsible } from '@/components/Collapsible';

import styles from './MemoDropdown.module.scss';

interface MemoDropdownProps {
  memo: string;
  onMemoChange: (newMemo: string) => void;
}

export function MemoDropdown({ memo, onMemoChange }: MemoDropdownProps) {
  const t = useTranslations('Components.WalletConnect.MemoDropdown');

  return (
    <div className={styles.container}>
      <Collapsible
        triggerLabel={t('addMemo')}
        data-testid="wallet-connect-modal.memo-dropdown"
        defaultOpen={Boolean(memo)}
      >
        <Form.Group className={styles.container}>
          <Form.Label htmlFor="wallet-connect-modal-memo">{t('memoLabel')}</Form.Label>
          <Form.TextArea
            value={memo}
            onChange={(e) => onMemoChange(e.target.value)}
            placeholder={t('memoPlaceholder')}
            rows={3}
            id="wallet-connect-modal-memo"
          />
        </Form.Group>
      </Collapsible>
    </div>
  );
}
