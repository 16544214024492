import { capitalize } from '@walletconnect/utils';

export type Signature = {
  id: number;
  textSignature: string;
  bytesSignature: string;
  hexSignature: string;
  callMethod: string;
};

type ApiResponse = {
  results: Array<{
    id: number;
    created_at: string;
    text_signature: string;
    bytes_signature: string;
    hex_signature: string;
  }>;
};

export namespace Signatures {
  const baseUrl = 'https://www.4byte.directory/api/v1/signatures';

  /**
   * Retrieves a signature by its hexadecimal code from the 4byte.directory API and returns a detailed signature object.
   * @param code The hexadecimal code of the signature to retrieve.
   * @returns A Promise that resolves to a Signature object if found, otherwise returns null.
   */
  export const getSignatureByHexCode = async (code: string): Promise<Signature | null> => {
    const url = `${baseUrl}?hex_signature=${code}`;

    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: ApiResponse = await response.json();

      if (data.results.length === 0) {
        return null;
      }
      // Sort the results by created_at in ascending order
      const sortedResults = data.results.sort(
        (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      );
      const firstResult = sortedResults[0];

      const callMethod = firstResult.text_signature.split('(')[0];

      return {
        id: firstResult.id,
        textSignature: firstResult.text_signature,
        bytesSignature: firstResult.bytes_signature,
        hexSignature: firstResult.hex_signature,
        callMethod: capitalize(callMethod),
      };
    } catch (err) {
      console.error('Failed to fetch signature:', err);
      return null;
    }
  };

  /**
   * Retrieves the method name of a signature by its hexadecimal code using the 4byte.directory API.
   * If the signature is found, it returns the method name; otherwise, it returns the input code.
   * @param code The hexadecimal code of the signature.
   * @returns A Promise that resolves to the method name of the signature if found, otherwise returns the input code.
   */
  export const fetchSignatureMethod = async (code: string): Promise<string> => {
    const signature = await Signatures.getSignatureByHexCode(code);
    return signature ? signature.callMethod : code;
  };
}
