import { Network } from '@/lib/models';

/**
 * List of disallowed chain IDs.
 */
export const disallowedNetworkTypes = [Network.Type.Bitcoin, Network.Type.IoNetwork, Network.Type.TestnetIoNetwork];

/**
 * Signature Types
 */
export enum SignatureType {
  EIP712 = 'EIP712',
  EIP155 = 'EIP155',
}

/**
 * EIP712 Type
 */
export interface EIP712 {
  primaryType: string;
  domain: Record<string, unknown>;
  message: Record<string, unknown>;
  types: { [key: string]: { type: string; name: string }[] };
}

/**
 * Transaction object Type
 */
export type TransactionObject = {
  data: string;
  from: string;
  to: string;

  gas: string;

  gasLimit?: string;
  gasPrice?: string;
  value?: string;
  nonce?: string;
};

/**
 * Definition List Type
 */
export type DefinitionList = { title: string; description: string }[];

/**
 * Generic Sign Content Type
 */
export type GenericSignContent = string | DefinitionList;

/**
 * Generic Message Type
 */
export type GenericMessage = {
  type: 'generic-message';
  address: string;
  heading?: string;
  message: DefinitionList;
  rawMessage?: string | EIP712;
};
