'use client';
import React, { useCallback, useState, useEffect } from 'react';
import cx from 'classnames';
import * as RadixCollapsible from '@radix-ui/react-collapsible';

import { Icons } from '@/lib/io-kit/Icons';
import { Button } from '@/lib/io-kit/Button';
import { buildTestId } from '@/lib/test-ids';

import styles from './Collapsible.module.scss';

type Props = {
  buttonSize?: 'small' | 'medium';
  defaultOpen?: boolean;
  'data-testid'?: string;
  children: React.ReactNode;
  triggerLabel: string;
  variant?: 'standard' | 'inline';
  isOpen?: boolean;
  onToggle?: (open: boolean) => void;
};

export function Collapsible({
  buttonSize = 'medium',
  defaultOpen = false,
  children,
  'data-testid': testId,
  triggerLabel,
  variant = 'standard',
  isOpen,
  onToggle,
}: Props) {
  const [isOpenInternal, setIsOpenInternal] = useState(defaultOpen);

  const handleOpenChange = useCallback(
    (open: boolean) => {
      setIsOpenInternal((prevIsOpen) => !prevIsOpen);
      if (onToggle) {
        onToggle(open);
      }
    },
    [onToggle],
  );

  useEffect(() => {
    if (isOpen !== undefined) {
      setIsOpenInternal(isOpen);
    }
  }, [isOpen]);

  return (
    <RadixCollapsible.Root
      className={cx(styles.container, {
        [styles.transparentContainer]: variant === 'inline',
      })}
      open={isOpenInternal}
      onOpenChange={handleOpenChange}
    >
      <RadixCollapsible.Trigger
        asChild
        className={cx(styles.trigger, {
          [styles.paddingLess]: variant === 'inline',
        })}
        data-testid={testId}
      >
        <Button
          as="button"
          variant="unstyled"
          iconAfter={isOpenInternal ? <Icons.RemoveCircleFilled /> : <Icons.AddCircleFilled />}
          className={cx(styles.button, styles[buttonSize], {
            [styles.paddingLess]: variant === 'inline',
          })}
          data-testid={buildTestId(testId, 'trigger')}
        >
          {triggerLabel}
        </Button>
      </RadixCollapsible.Trigger>

      <RadixCollapsible.Content
        className={cx(styles.dropdownContent, {
          [styles.paddingLess]: variant === 'inline',
        })}
      >
        {children}
      </RadixCollapsible.Content>
    </RadixCollapsible.Root>
  );
}
