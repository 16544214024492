'use client';
import { ChangeEvent, KeyboardEventHandler, useCallback, useMemo, useRef, useState, useTransition } from 'react';
import debounce from 'lodash.debounce';
import { useRouter, useSearchParams } from 'next/navigation';

import { LinkTo } from '@/lib/links';
import { Form } from '@/lib/io-kit/Form';
import { Icons } from '@/lib/io-kit/Icons';

const CHARACTER_THRESHOLD = 2;
const DEBOUNCE_INTERVAL = 350;

export default function FilterDapps() {
  const searchParams = useSearchParams();
  const [isPending, startTransition] = useTransition();
  const router = useRouter();

  const [trackQuery, setTrackQuery] = useState<string | null>(searchParams.get('query') ?? '');
  const inputRef = useRef<HTMLInputElement>(null);
  const localQueryRef = useRef<string | null>(null);

  const debouncedTransition = useMemo(
    () =>
      debounce(
        (query: string, searchParams: ReturnType<typeof useSearchParams>) => {
          async function doReplace() {
            if (localQueryRef.current !== query) return;
            localQueryRef.current = null;

            const newSearchParams = new URLSearchParams(searchParams);
            if (query) {
              newSearchParams.set('query', query);
            } else {
              newSearchParams.delete('query');
            }
            // Reset page when query is set or updated
            newSearchParams.delete('before');
            newSearchParams.delete('after');

            router.replace(LinkTo.dapps({ searchParams: Object.fromEntries(newSearchParams) }));

            if (newSearchParams.get('query') === null) {
              router.refresh();
            }
          }

          startTransition(async () => {
            await doReplace();
          });
        },
        DEBOUNCE_INTERVAL,
        { trailing: true },
      ),
    [router],
  );

  const search = useCallback(
    (term: string) => {
      if (term === searchParams.get('query')) return;

      const isNewTermLongerThanCurrentInParams = term.length > (searchParams.get('query') || '').length;
      if (term !== '' && isNewTermLongerThanCurrentInParams && term.length < CHARACTER_THRESHOLD) {
        return;
      }

      localQueryRef.current = term;

      if (term === '') {
        debouncedTransition.cancel();
      }

      debouncedTransition(term, searchParams);
    },
    [debouncedTransition, searchParams],
  );

  const handleSearch = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      const term = evt.target.value;
      setTrackQuery(term);
      search(term);
    },
    [search],
  );

  const clearInput = useCallback(() => {
    if (inputRef.current && inputRef.current.value === '') return;

    if (inputRef.current) {
      setTrackQuery('');
      inputRef.current.value = '';
    }
    search('');
  }, [search]);

  const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const currentInputValue = inputRef.current?.value;
      if (event.key !== 'Escape' || currentInputValue === '') return;

      event.stopPropagation();

      clearInput();
    },
    [clearInput],
  );

  return (
    <Form.Input
      placeholder="Filter dapps"
      ref={inputRef}
      defaultValue={searchParams.get('query')?.toString()}
      iconBefore={isPending ? <Icons.Spinner spin aria-hidden="true" /> : <Icons.Search aria-hidden="true" />}
      iconAfter={trackQuery && <Icons.Close onClick={clearInput} aria-hidden="true" focusable="false" />}
      onChange={handleSearch}
      onKeyDown={handleKeyPress}
    />
  );
}
