import cx from 'classnames';

import { CopyValue } from '@/components/CopyValue';
import { buildTestId } from '@/lib/test-ids';
import { truncateValue } from '@/lib/truncate-value';

import { OpenInExplorerIconButton } from './OpenInExplorerIconButton';
import styles from './CryptoAddress.module.scss';

type Props = {
  value: string;
  disableCopy?: boolean;
  showCopyIcon?: boolean;
  explorerUri?: undefined | null | string;
  className?: string;
  'data-testid'?: string;
};

export function CryptoAddressOrId({
  value,
  disableCopy = false,
  showCopyIcon,
  explorerUri,
  className,
  'data-testid': testId,
}: Props) {
  return (
    <span className={cx(styles.container, className)}>
      {disableCopy ? (
        <span className={styles.rawValue} title={value}>
          {truncateValue(value)}
        </span>
      ) : (
        <CopyValue value={value} size="small" showIcon={showCopyIcon ?? false} data-testid={testId} />
      )}

      {!!explorerUri && (
        <OpenInExplorerIconButton explorerUri={explorerUri} data-testid={buildTestId(testId, 'explorer-link')} />
      )}
    </span>
  );
}

export { OpenInExplorerIconButton } from './OpenInExplorerIconButton';
