import { TransactionRequest } from 'viem';
import { IOWeb3HttpProvider, RequestMetadata, RequestData } from '@iofinnet/io-web3-provider';

import { getBrowserAccessToken } from '@/lib/auth/browser';
import { Env } from '@/lib/env';
import { globalLogger } from '@/lib/logger';
import { EIP155_CHAINS } from '@/lib/wallet-connect';

const logger = globalLogger.child({ scope: 'WalletConnect' });

/**
 * Types
 */
interface IInitArgs {
  chainId?: string;
}

interface CommonParams {
  data: RequestData;
  metadata?: RequestMetadata;
}

interface TransactionParams extends CommonParams {
  transaction: TransactionRequest;
}

interface MessageParams extends CommonParams {
  message: string;
}

interface TypedDataParams extends CommonParams {
  domain: any;
  types: any;
  typeData: any;
  _primaryType?: string;
}

export interface EIP155Wallet {
  connect(): Promise<string[]>;
  getAddress(): Promise<string>;
  sendTransaction(params: TransactionParams): Promise<string>;
  signTransaction(params: TransactionParams): Promise<string>;
  signMessage(params: MessageParams): Promise<string>;
  signTypedData(params: TypedDataParams): Promise<string>;
  switchEthereumChain(chainId: string): Promise<void>;
}

/**
 * Library
 */
export class EIP155Lib implements EIP155Wallet {
  ioProvider: IOWeb3HttpProvider;

  constructor(ioProvider: IOWeb3HttpProvider) {
    this.ioProvider = ioProvider;
  }

  static init({ chainId = '1' }: IInitArgs = {}): EIP155Lib {
    logger.debug('Initializing Provider for chainId', chainId);
    const ioProvider = new IOWeb3HttpProvider({
      ioApi: new URL(Env.gql.endpoint),
      initialChainId: `0x${chainId}`,
      chains: (() => {
        const chainsObj: { [key: string]: { name: string; rpcUrl: string } } = {};
        for (const [key, value] of Object.entries(EIP155_CHAINS)) {
          const [, ref] = key.split(':');
          chainsObj[`0x${ref}`] = {
            name: value.name,
            rpcUrl: value.rpc,
          };
        }
        return chainsObj;
      })(),
      tokenRetrievalCallback: async () => {
        const token = await getBrowserAccessToken();
        if (!token) {
          throw new Error('No Access token found');
        }
        return token;
      },
    });

    return new EIP155Lib(ioProvider);
  }

  async connect(): Promise<string[]> {
    const requestArgs = {
      method: 'eth_requestAccounts',
      params: [],
    };

    const response = await this.ioProvider.request(requestArgs);

    const accounts: string[] = response.result;

    return accounts;
  }

  async getAddress(): Promise<string> {
    const requestArgs = {
      method: 'eth_accounts',
      params: [],
    };

    const response = await this.ioProvider.request(requestArgs);

    const accounts: string[] = response.result;

    if (accounts.length === 0) throw new Error('No accounts found.');

    return accounts[0];
  }

  async sendTransaction({ transaction, data, metadata }: TransactionParams): Promise<string> {
    const requestArgs = {
      method: 'eth_sendTransaction',
      params: [transaction],
      data,
      metadata,
    };

    const response = await this.ioProvider.request(requestArgs);

    const transactionHash: string = response.result;

    return transactionHash;
  }

  async signTransaction({ transaction, data, metadata }: TransactionParams): Promise<string> {
    const requestArgs = {
      method: 'eth_sign',
      params: [transaction],
      data,
      metadata,
    };

    const response = await this.ioProvider.request(requestArgs);

    const signedMessage: string = response.result;

    return signedMessage;
  }

  async signMessage({ message, data, metadata }: MessageParams): Promise<string> {
    const requestArgs = {
      method: 'personal_sign',
      params: [message],
      data,
      metadata,
    };

    const response = await this.ioProvider.request(requestArgs);

    const signedMessage: string = response.result;

    return signedMessage;
  }

  async signTypedData({ domain, types, typeData, _primaryType, data, metadata }: TypedDataParams): Promise<string> {
    const requestArgs = {
      method: 'eth_signTypedData',
      params: [{ domain, types, message: typeData, primaryType: _primaryType }],
      data,
      metadata,
    };

    const response = await this.ioProvider.request(requestArgs);
    const signedData: string = response.result;

    return signedData;
  }

  async switchEthereumChain(chainId: string): Promise<void> {
    const splitChainId = +chainId.split(':')[1];
    const formattedChainId = `0x${splitChainId}`;

    const requestArgs = {
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: formattedChainId }],
    };

    await this.ioProvider.request(requestArgs);
  }
}
