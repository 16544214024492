'use client';
import { useCallback, useState } from 'react';
import { useTranslations } from 'next-intl';
import cx from 'classnames';

import { showDismissableAlertToast } from '@/components/Toasts';
import { useWalletConnectState } from '@/lib/wallet-connect/browser';
import { approveEIP155Request, getSignParamsMessage, rejectEIP155Request } from '@/lib/wallet-connect';

import { ModalWrapper } from '../modal-wrapper';
import { MemoDropdown } from '../_components/MemoDropdown';

import styles from './SessionSignModal.module.scss';

export function SessionSignModal() {
  const t = useTranslations('Components.WalletConnect.Modals');

  const { web3Wallet, modal, mutations, settings } = useWalletConnectState();

  const requestEvent = modal.data?.requestEvent;
  const requestSession = modal.data?.requestSession;
  const [memo, setMemo] = useState('');
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [isLoadingReject, setIsLoadingReject] = useState(false);

  // Handle approve action
  const onApprove = useCallback(async () => {
    if (requestEvent) {
      setIsLoadingApprove(true);
      try {
        mutations.openModal('SessionSubmittedModal', modal.data);
        const response = await approveEIP155Request({
          requestEvent,
          requestSession,
          data: {
            vaultId: settings.activeVault.id,
            memo,
          },
        });
        await web3Wallet.respondSessionRequest({
          topic: requestEvent.topic,
          response,
        });
      } catch (err) {
        showDismissableAlertToast('Error', (err as Error).message, 'error');
      } finally {
        setIsLoadingApprove(false);
        mutations.closeModal();
      }
    }
  }, [requestEvent, mutations, modal.data, requestSession, settings.activeVault.id, memo, web3Wallet]);

  // Handle reject action
  const onReject = useCallback(async () => {
    if (requestEvent) {
      setIsLoadingReject(true);
      const response = rejectEIP155Request(requestEvent);
      try {
        await web3Wallet.respondSessionRequest({
          topic: requestEvent.topic,
          response,
        });
      } catch (err) {
        showDismissableAlertToast('Error', (err as Error).message, 'error');
      } finally {
        setIsLoadingReject(false);
        mutations.closeModal();
      }
    }
  }, [requestEvent, web3Wallet, mutations]);

  // Ensure request and wallet are defined
  if (!requestEvent || !requestSession) {
    return <div className={styles.text}>{t('Common.missingRequestData')}</div>;
  }

  // Get required request data
  const { params } = requestEvent;
  const { request } = params;

  // Get message, convert it to UTF8 string if it is valid hex
  const message = getSignParamsMessage(request.params);

  return (
    <ModalWrapper
      intention={t('SessionTypes.Sign.intention')}
      metadata={requestSession.peer.metadata}
      onApprove={onApprove}
      onReject={onReject}
      approveLoader={{ active: isLoadingApprove }}
      rejectLoader={{ active: isLoadingReject }}
    >
      <div className={styles.row}>
        <div>
          <h5 className={styles.heading}>{t('Common.message')}</h5>
          <p className={cx(styles.text, styles.textLight)} data-testid="request-message-text">
            {message}
          </p>
        </div>
      </div>
      <div className={styles.memo}>
        <MemoDropdown memo={memo} onMemoChange={setMemo} />
      </div>
    </ModalWrapper>
  );
}
