import { Web3Wallet, IWeb3Wallet } from '@walletconnect/web3wallet';
import { Core, RELAYER_DEFAULT_RELAY_URL } from '@walletconnect/core';

import { Env } from '@/lib/env';

export async function createWeb3Wallet(relayerRegionURL?: string) {
  const core = new Core({
    projectId: Env.walletConnect.projectId,
    relayUrl: relayerRegionURL ?? RELAYER_DEFAULT_RELAY_URL,
    logger: process.env.NODE_ENV === 'production' ? 'error' : 'debug',
  });

  const web3Wallet = await Web3Wallet.init({
    core,
    metadata: {
      name: 'io.finnet',
      description: 'Securely connect your io.vault to the best dApps through Wallet Connect.',
      url: 'https://iofinnet.com/',
      icons: ['/vault-with-iofinnet-logo.svg'],
    },
  });

  // verify initialization
  try {
    await web3Wallet.engine.signClient.core.crypto.getClientId();
  } catch (err) {
    console.error('Failed to load WalletConnect clientId:', err);
    // TODO handle translation with 'Toasts.WalletConnectToasts.LoadClientIdError'
    // showDismissableAlertToast(t('title'), t('description'), 'error');
  }

  return web3Wallet;
}

export async function updateWeb3WalletSessions(web3Wallet: IWeb3Wallet, chainId: string, address: string) {
  // get most recent session
  const sessions = web3Wallet.getActiveSessions();
  if (!sessions) return;

  try {
    const namespace = chainId.split(':')[0];
    for (const session of Object.values(sessions)) {
      await web3Wallet.updateSession({
        topic: session.topic,
        namespaces: {
          ...session.namespaces,
          [namespace]: {
            ...session.namespaces[namespace],
            chains: [...new Set([chainId, ...(session?.namespaces?.[namespace]?.chains || [])])],
            accounts: [...new Set([`${chainId}:${address}`, ...(session?.namespaces?.[namespace]?.accounts || [])])],
          },
        },
      });

      const chainChanged = {
        topic: session.topic,
        event: {
          name: 'chainChanged',
          data: Number.parseInt(chainId.split(':')[1]),
        },
        chainId: chainId,
      };

      const accountsChanged = {
        topic: session.topic,
        event: {
          name: 'accountsChanged',
          data: [`${chainId}:${address}`],
        },
        chainId,
      };

      await new Promise((resolve) => setTimeout(resolve, 1000));
      await web3Wallet.emitSessionEvent(accountsChanged);
      await web3Wallet.emitSessionEvent(chainChanged);
    }
  } catch (err) {
    console.error('Failed to update sessions:', err);
  }
}

export async function refreshWeb3WalletSessions(web3Wallet: IWeb3Wallet) {
  const sessions = web3Wallet.getActiveSessions();
  if (!sessions) return;

  try {
    for (const session of Object.values(sessions)) {
      // Extend the session by an additional 7 days
      await web3Wallet.extendSession({ topic: session.topic });
    }
  } catch (err) {
    console.error('Failed to refresh sessions:', err);
  }
}

export const isPairingUri = (uri: string): boolean => {
  return uri.startsWith('wc:');
};
